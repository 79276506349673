<template>
    <div class="background-cream" style="padding-top: 200px; padding-bottom: 250px;">
        <v-container>
            <v-row>
                <v-col>
                    <table style="width: 100%;">
                        <thead>
                            <tr>
                                <th class="text-left">First Name</th>
                                <th class="text-left">Last Name</th>
                                <th class="text-left">Email</th>
                                <th class="text-left">Preferred Date</th>
                                <th class="text-left">Suggested Hour</th>
                                <th class="text-left">Status</th>
                            </tr>
                        </thead>
                        <template v-for="(prospect,p) in prospects">
                            <tr :key="'prospect-'+p">
                                <td class="pt-3">
                                    {{prospect.firstName}}
                                </td>
                                <td class="pt-3">
                                    {{prospect.lastName}}
                                </td>
                                <td class="pt-3">
                                    {{prospect.email}}
                                </td>
                                <td class="pt-3">
                                    {{formateDate(prospect.preferredDate)}}
                                </td>
                                <td class="pt-3">
                                    {{prospect.suggestedHour}}
                                </td>
                                <td class="pt-3">
                                    <template v-if="prospect.status == 'Pending'">
                                        <v-btn color="success" max-width="300" class="mx-auto mr-3" @click="selectedProspect = prospect; tourModal = true">accept</v-btn>
                                        <v-btn color="warning" max-width="300" class="mx-auto " @click="moreInfoModal = true; selectedProspect = prospect">need more info</v-btn>
                                    </template>
                                    <template v-else>
                                        <v-btn>

                                        {{prospect.status}}
                                        </v-btn>
                                    </template>
                                </td>
                            </tr>
                        </template>
                    </table>
                </v-col>
            </v-row>
        </v-container>

        <v-dialog v-model="tourModal" v-if="selectedProspect != null" persistent max-width="600px">
            <v-card color="#ece7e1">
                <div style="position: absolute; top: 3px; right: 3px">
                <v-btn icon color="black" @click="tourModal = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                </div>
                <v-card-title class="text-center">
                    <div class="text-center" style="width: 100%">
                        <span
                        class="
                            black--text
                            subtitles-big
                            d-block
                            founders-grotesk-light
                            animation
                            line-height-title-intro
                            text-center
                            d-block
                        "
                        >Client Profile</span
                        >
                    </div>
                </v-card-title>
                <v-card-text class="pb-0">
                    <v-container>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-row>

                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="selectedProspect.firstName"
                                        label="First name*"
                                        required
                                        color="black"
                                        :disabled="true"
                                        :rules="requiredRules"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="selectedProspect.lastName"
                                        label="Last name*"
                                        required
                                        color="black"
                                        :disabled="true"
                                        :rules="requiredRules"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="6">
                                    <v-text-field
                                        v-model="selectedProspect.email"
                                        label="Email*"
                                        required
                                        color="black"
                                        :disabled="true"
                                        :rules="emailRules"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="6">
                                    <v-menu
                                        v-model="menu2"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="selectedProspect.preferredDate"
                                            label="Preferred date"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            color="black"
                                            :disabled="sending"
                                            :rules="requiredRules"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        v-model="date"
                                        @input="menu2 = false"
                                        color="black"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col cols="6">
                                    <v-select
                                        :items="items"
                                        label="Time"
                                        color="black"
                                        v-model="selectedProspect.suggestedHour"
                                        :rules="requiredRules"
                                    ></v-select>
                                </v-col>
                                
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions class="py-4 pt-0">
                <v-spacer></v-spacer>
                <div class="text-center">
                    <v-btn
                    color="black"
                    elevation="0"
                    tile
                    width="219px"
                    @click="sendConfirmation()"
                    :disabled="sending"
                    >
                    <span
                        class="white--text continuos-texts"
                        style="text-transform: none"
                        >Confirm</span
                    >
                    </v-btn>

                    <div
                    class="opac-item mt-3 text-center"
                    v-bind:class="{ on: sending }"
                    >
                    <span class="continuos-texts">
                        Thank you. Your info has been saved.
                    </span>
                    </div>
                </div>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="moreInfoModal" v-if="selectedProspect != null" persistent max-width="600px">
            <v-card color="#ece7e1">
                <div style="position: absolute; top: 3px; right: 3px">
                    <v-btn icon color="black" @click="moreInfoModal = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-title class="text-center">
                    <div class="text-center" style="width: 100%">
                        <span
                        class="
                            black--text
                            subtitles-big
                            d-block
                            founders-grotesk-light
                            animation
                            line-height-title-intro
                            text-center
                            d-block
                        "
                        >Client Profile</span
                        >
                    </div>
                </v-card-title>
                <v-card-text class="pb-0">
                    <v-container>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-row>

                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="selectedProspect.firstName"
                                        label="First name*"
                                        required
                                        color="black"
                                        :disabled="true"
                                        :rules="requiredRules"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="selectedProspect.lastName"
                                        label="Last name*"
                                        required
                                        color="black"
                                        :disabled="true"
                                        :rules="requiredRules"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="6">
                                    <v-text-field
                                        v-model="selectedProspect.email"
                                        label="Email*"
                                        required
                                        color="black"
                                        :disabled="true"
                                        :rules="emailRules"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="6">
                                    <v-menu
                                        v-model="menu2"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="selectedProspect.preferredDate"
                                            label="Preferred date"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            color="black"
                                            :disabled="true"
                                            :rules="requiredRules"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        v-model="date"
                                        @input="menu2 = false"
                                        color="black"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col cols="6">
                                    <v-select
                                        :items="items"
                                        label="Suggested Hour"
                                        color="black"
                                        v-model="selectedProspect.suggestedHour"
                                        :disabled="true"
                                        :rules="requiredRules"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions class="py-4 pt-0">
                    <v-spacer></v-spacer>
                    <div class="text-center">
                        <v-btn
                        color="black"
                        elevation="0"
                        tile
                        @click="requestMoreInfo()"
                        :disabled="sending"
                        >
                            <span
                                class="white--text continuos-texts"
                                style="text-transform: none"
                                >Info Requested</span
                            >
                        </v-btn>

                        <div
                        class="opac-item mt-3 text-center"
                        v-bind:class="{ on: sending }"
                        >
                        <span class="continuos-texts">
                            Thank you. Your info has been saved.
                        </span>
                        </div>
                    </div>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- {{ prospects }} -->
    </div>
</template>

<script>
import Vue from 'vue'
import VueSession from 'vue-session'
Vue.use(VueSession,{persist: true})
import axios from "axios";
export default {
    beforeMount: function () {
        if(!this.$session.has('user')){
            this.$router.push('/access');
        }
    },
    mounted: function () {
        this.getData()
    },
    data() {
        return {
            prospects: [],
            tourModal: false,
            moreInfoModal: false,
            selectedProspect: null,
            items: ['9:00am', '10:00am', '11:00am', '12:00pm', '1:00pm', '2:00pm', '3:00pm', '4:00pm', '5:00pm',],
        }
    },
    computed: {
        user: function () {
            return this.$session.get('user')
        }
    },
    methods: {
        getData: function (){
            let aux = this;

            axios.post('https://content.marisolmalibu.com/api/collections/get/Tours?sort[_created]=-1&token='+this.user.api_key)
            .then(res => aux.prospects = res.data.entries);

            // fetch('https://content.marisolmalibu.com/api/collections/get/Tours?sort[_created]=-1&token='+this.user.api_key)
            // .then(res => res.json())
            // .then(res => aux.prospects = res.entries);
        },
        sendConfirmation: function () {
            let aux = this;
            this.selectedProspect.status = 'Accepted';
            fetch('https://content.marisolmalibu.com/api/collections/save/Tours?token='+this.user.api_key, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    data: this.selectedProspect
                })
            })
            .then(res=>res.json())
            .then(() => this.mailAccepted())
            .then((() => aux.getData()) )
        },
        mailAccepted: function () {
            this.tourModal = false

            const form = new FormData();
            let dateToFormat = this.selectedProspect.preferredDate;
            let arrayDate = dateToFormat.split("-");
            let newDate = arrayDate[1] + '-' + arrayDate[2] + '-' +arrayDate[0];
            console.log(newDate);

            form.append('from', 'Marisol Malibu <visit@marisolmalibu.com>');
            form.append('to', this.selectedProspect.email);
            form.append('template', 'marisolmalibu-accepted-tour');
            form.append('h:X-Mailgun-Variables', '{"email": "'+this.selectedProspect.email+'","firstName": "'+this.selectedProspect.firstName+'", "lastName": "'+this.selectedProspect.lastName+'","preferredDate":"'+newDate+'", "suggestedHour":"'+this.selectedProspect.suggestedHour+'"}');

            fetch('https://api.mailgun.net/v3/mg.marisolmalibu.com/messages', {
                method: 'POST',
                headers: {
                    'Authorization': 'Basic ' + btoa('api:fc4d204389de92fe77e32e2b78bc227a-73f745ed-ebdc55df')
                },
                body: form
            });
            // fetch("https://www.utils.actstudio.xyz/accepted.php?referencia="+this.selectedProspect._id)
        },
        requestMoreInfo: function () {
            let aux = this;
            this.selectedProspect.status = 'Request more info';
            fetch('https://content.marisolmalibu.com/api/collections/save/Tours?token='+this.user.api_key, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    data: this.selectedProspect
                })
            })
            .then(res=>res.json())
            .then(() => this.mailRequestMoreInfo())
            .then((() => aux.getData()) );
        },
        getFormattedDate: function (date) {
            let dateToFormat = date;
            let arrayDate = dateToFormat.split("-");
            let newDate = arrayDate[1] + '/' + arrayDate[2] + '/' +arrayDate[0];
            return newDate;
        },
        getNameDay: function (date) {
            let dateToFormat = date;
            let arrayDate = dateToFormat.split("-");
            let newDate = arrayDate[1] + '-' + arrayDate[2] + '-' +arrayDate[0];
            let nDate = new Date(newDate);
            let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            let dayName = days[nDate.getDay()];
            return dayName;
        },
        mailRequestMoreInfo: function () {
            this.moreInfoModal = false

            let subject = this.getNameDay(this.selectedProspect.preferredDate) + ' ' + this.getFormattedDate(this.selectedProspect.preferredDate) + ' ' + this.selectedProspect.suggestedHour + ' Reservation Request for 11809 Ellice St'; 

            

            const form = new FormData();
            form.append('from', 'Marisol Malibu <visit@marisolmalibu.com>');
            // form.append('to', 'eugenio@actstudio.mx');
            form.append('to', this.selectedProspect.email);
            form.append('subject', subject);
            form.append('template', 'marisolmalibu-request-more-info-booking');
            form.append('h:X-Mailgun-Variables', '{"email": "'+this.selectedProspect.email+'","preferredDate":"'+this.getFormattedDate(this.selectedProspect.preferredDate)+'", "suggestedHour":"'+this.selectedProspect.suggestedHour+'"}');

            fetch('https://api.mailgun.net/v3/mg.marisolmalibu.com/messages', {
                method: 'POST',
                headers: {
                    'Authorization': 'Basic ' + btoa('api:fc4d204389de92fe77e32e2b78bc227a-73f745ed-ebdc55df')
                },
                body: form
            });

            // fetch("https://www.utils.actstudio.xyz/moreinfo.php?referencia="+this.selectedProspect._id)
        },
        formateDate : function(date) {
            let dateToFormat = date;
            let arrayDate = dateToFormat.split("-");
            let newDate = arrayDate[1] + '-' + arrayDate[2] + '-' +arrayDate[0];
            return newDate;
        }
    }
}
</script>